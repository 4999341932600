import { cn } from "@/lib/utils";
import { ColumnDefTemplate, HeaderContext } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import { t } from "i18next";
import { DeviceDataType, PatientDeviceType, PatientType, UserType } from "@/types";
import moment from "moment-timezone";

const renderColumnHeader: ColumnDefTemplate<HeaderContext<never, any>> = ({ column }) => {
  let headerLabel = "";

  switch (column.id) {
    case "name":
      headerLabel = t("devicesScreen.table.deviceName");
    break;
    case "patient_name":
      headerLabel = t("devicesScreen.table.patientName");
    break;
    case "date_created":
      headerLabel = t("devicesScreen.table.dateCreated");
    break;
    case "date_updated":
      headerLabel = t("devicesScreen.table.dateUpdated");
    break;
    case "created_by":
      headerLabel = t("devicesScreen.table.createdBy");
    break;
    case "added_by":
      headerLabel = t("devicesScreen.table.addedBy");
    break;
    case "is_online":
      headerLabel = t("devicesScreen.table.isOnline");
    break;
    case "app_version":
      headerLabel = t("devicesScreen.table.appVersion");
    break;
    default: 
      headerLabel = "";
  }

  const getIsSorted = column.getIsSorted();

  return column.getCanSort() ? (
    <Button
      className={cn("focus-visible:ring-0 focus:ring-0 px-0", getIsSorted && "font-semibold")}
      variant="ghost"
      onClick={() => column.toggleSorting(getIsSorted === "asc")}
    >
      {headerLabel}
      {getIsSorted && (
        <ArrowUpDown className="ml-2 h-4 w-4" />
      )}
    </Button>
  ) : headerLabel;
};

const composeDevicesData = (
  patientsDevices: PatientDeviceType[],
  patients: PatientType[],
  accountUsers: UserType[],
) : DeviceDataType[] => {
  const DEVICE_STATUS_UPDATE_TIMEOUT = 1000 * 60;
  const patientsById: Record<string, PatientType | undefined> = patients.reduce((acc, patient) => ({
    ...acc,
    [patient.id]: patient,
  }), {});

  const accountUsersById: Record<string, UserType | undefined> = accountUsers.reduce((acc, accountUser) => ({
    ...acc,
    [accountUser.id]: accountUser,
  }), {});

  return patientsDevices.reduce((acc, patientDevice) => {
    return acc.concat({
      ...patientDevice,
      patient_name: patientDevice.patient_id
        ? patientsById[patientDevice.patient_id]?.name ?? `${(patientDevice.patient_id)}`
        : "N/A",
      app_version: patientDevice.metadata?.app?.version ?? "N/A",
      created_by: patientDevice.created_by
        ? accountUsersById[patientDevice.created_by]?.name ?? `(${patientDevice.created_by})`
        : t("devicesScreen.table.createdAuto"),
      added_by: patientDevice.added_by
        ? accountUsersById[patientDevice.added_by]?.name ?? `(${patientDevice.added_by})`
        : patientDevice.is_connected ? t("devicesScreen.table.createdAuto") : "N/A",
      name: patientDevice.device_name ?? patientDevice.metadata?.device.deviceName ?? "",
      is_online: patientDevice.date_updated
        ? moment().diff(moment(patientDevice.date_updated), "milliseconds") <= DEVICE_STATUS_UPDATE_TIMEOUT
        : false,
    });
  }, [] as DeviceDataType[]);
};

export {
  renderColumnHeader,
  composeDevicesData
};