// API layer

import Axios, { AxiosProgressEvent } from "axios";
import moment from "moment-timezone";
import {
  MediaCategoryDataType,
  MediaContentDataType,
  MediaType,
  PatientDeviceType,
  PatientSettingsType,
  PatientType,
  UserType,
} from "./types";
import { PatientSettingsFormSchema } from "./config";

// !TODO: name from common library
const apiImportMediaLibraryContent = async (
  parentCategoryId: string | undefined,
  mediaResourceItems: MediaContentDataType[],
  mediaCategoryItems: MediaCategoryDataType[],
  apiPrefix: string,
  copyLinkedPlaylist: boolean = false,
) => {
  const usedCategoryId = parentCategoryId ?? "root";
 
  for (const mediaResourceItem of mediaResourceItems) {
    const isPlaylist = mediaResourceItem.media_type === "playlist";

    const result = isPlaylist && copyLinkedPlaylist
      ? await Axios.post(`${apiPrefix}/category/${usedCategoryId}/copy_playlist`, {
          resource_id: mediaResourceItem.id,
          category_id: usedCategoryId,
        })
      : await Axios.post(`${apiPrefix}/category/${usedCategoryId}/resource`, {
          media_type: "shared_resource",
          linked_resource_id: mediaResourceItem.id,
          copy_linked_playlist: copyLinkedPlaylist,
          category_id: usedCategoryId,
        });

    if (result.status !== 201) {
      throw new Error("Error importing resources");
    }
  }

  for (const mediaCategoryItem of mediaCategoryItems) {
    const result = await Axios.post(`${apiPrefix}/category/${usedCategoryId}/resource`, {
      category_id: usedCategoryId,
      media_type: "shared_category",
      linked_resource_id: mediaCategoryItem.id,
      copy_linked_playlist: copyLinkedPlaylist,
    });

    if (result.status !== 201) {
      throw new Error("Error importing categories");
    }
  }
};

const apiAddPlaylistItems = async (
  mediaResourceItems: MediaContentDataType[],
  firstIndexToAdd: number,
  apiPrefix: string,
  playlistId: string,
  categoryId?: string | null,
) => {
  let sort_order = firstIndexToAdd;
      
  for (const { id: resource_id} of mediaResourceItems) {
    await Axios.post(
      `${apiPrefix}/category/${categoryId ?? "root"}/resource/${playlistId}/playlist`,
      { resource_id, sort_order }
    );

    sort_order += 1;
  }
};


const apiGetMediaLibraryCategories = async (apiPrefix?: string) => {
  const categoriesData: MediaCategoryDataType[] = (
    await Axios.get(`${apiPrefix}/category/common`)
  )?.data;

  if (!categoriesData) {
    throw new Error("error getting resources categories data");
  }

  return categoriesData;
};

const apiGetMediaLibraryResources = async (
  apiPrefix: string,
  filters?: Partial<MediaContentDataType & { media_type?: MediaType[]}>,
  includeLinks?: boolean,
) => {
  const resourcesData: MediaContentDataType = (
    await Axios.get(`${apiPrefix}/category/resource/common`, { params: { filters, includeLinks } })
  )?.data;

  if (!resourcesData) {
    throw new Error("error getting resources categories data");
  }

  return resourcesData;
};

const apiGetPatientSettings = async (patientId: string) => {
  const responseData = (await Axios.get(`/api/patient/${patientId}/settings`))?.data;
  const responseParsed = PatientSettingsFormSchema.safeParse(responseData);

  let result = responseParsed.success
    ? responseParsed.data as PatientSettingsType
    : null;

  return result;
};

const apiUpdatePatientSettings = async(
  patientId: string,
  settings: PatientSettingsType,
) => {
  const result = (await Axios.put(`/api/patient/${patientId}/settings`, {
    settings,
  }))?.data;

  return result;
};

const apiRemoveMediaResourceBlob = async (
  apiPrefix: string,
  mediaResourceId: string,
  mediaResourceCategoryId: string,
  blobName: string
) => {
  const { status: removeBlobStatus } = await Axios.delete(
    `${apiPrefix}/category/${mediaResourceCategoryId}/resource/${mediaResourceId}/blob`,
    { params: { blobName } }
  );

  if (removeBlobStatus !== 200) {
    return false;
  }

  return true;
};

const apiCreateMediaResourceBlobUploadSasUrl = async (
  apiPrefix: string,
  mediaResourceId: string,
  mediaResourceCategoryId: string,
  blobFileName: string,
  blobNamePrefix?: string,
): Promise<{
  blobName: string;
  blobUploadSasUrl: string;
  blobPublicSasUrl: string;
}> => {
  const { status: createSasUrlStatus, data: createSasUrlData } = await Axios.post(
    `${apiPrefix}/category/${mediaResourceCategoryId}/resource/${mediaResourceId}/blob`,
    {
      filename: blobFileName,
      blobNamePrefix,
    },
  );

  if (createSasUrlStatus !== 201
    || !createSasUrlData?.blobUploadSasUrl
    || !createSasUrlData?.blobPublicSasUrl
    || !createSasUrlData?.blobName
  ) {
    throw new Error("Error creating resource blob SAS url");
  }

  return {
    blobName: createSasUrlData.blobName,
    blobUploadSasUrl: createSasUrlData.blobUploadSasUrl,
    blobPublicSasUrl: createSasUrlData.blobPublicSasUrl,
  };
};

const apiUploadMediaResourceBlobBySasUrl = async (
  uploadSasUrl: string,
  file: File,
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
) => {
  const headers = {
    Authorization: null,
    "Content-Type": file.type,
    "x-ms-blob-type": "BlockBlob",
  }

  const { status: uploadBlobStatus } = await Axios.put(
    uploadSasUrl,
    file,
    { headers, onUploadProgress  },
  );

  if (uploadBlobStatus !== 201) {
    throw new Error("Error uploading blob");
  }
};

const apiCreateMediaResource = async (
  apiPrefix: string,
  mediaResourceCategoryId: string,
  payload: any, // !TODO
): Promise<MediaContentDataType> => {
  const { status: createResStatus, data: createResData } = await Axios.post(
    `${apiPrefix}/category/${mediaResourceCategoryId}/resource`,
    payload,
  );

  if (createResStatus !== 201 || !createResData?.id) {
    throw new Error("Error creating new resource");
  }

  return createResData;
};

const apiUpdateMediaResource = async (
  apiPrefix: string,
  mediaResourceId: string,
  mediaResourceCategoryId: string | null,
  payload: any,
): Promise<MediaContentDataType> => {
  const { status: updateResStatus, data: updateResourceData } = await Axios.patch(
    `${apiPrefix}/category/${mediaResourceCategoryId ?? "root"}/resource/${mediaResourceId}`,
    payload,
  );

  if (updateResStatus !== 200) {
    throw new Error("Error updating resource");
  }

  return updateResourceData;
};
type CalendarEventDataType = any; // !TODO
const defaultTimezone = "Europe/Oslo";

const apiGetPatientCalendarEvents = async (
  patientId: string,
  accountId: string,
  startDate: string, // !DEAULT VALUE?
  endDate: string, // !DEAULT VALUE?
): Promise<CalendarEventDataType[]> => {
  const time_zone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? defaultTimezone;
  const utc_offset = moment.utc().tz(time_zone).utcOffset() ?? 0;
  
  const result = (await Axios.get(
    "/api/calendar",
    {
      params: {
        start_date: startDate,
        end_date: endDate,
        patient_id: patientId,
        account_id: accountId,
        time_zone,
        utc_offset,
      }
    }
  )).data ?? [];

  return result;
};

// !TODO: argument types
const apiCreatePatientCalendarEvent = async (eventData: any) => {
  const time_zone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? defaultTimezone;
  const utc_offset = moment.utc().tz(time_zone).utcOffset() ?? 0;

  const { status } = await Axios({
    method: "POST",
    url: "/api/calendar",
    data: {
      ...eventData,
      time_zone,
      utc_offset,
    }
  });

  if (status !== 201 && status !== 200) {
    return false;
  }

  return true;
};

// !TODO: argument types
const apiUpdatePatientCalendarEvent = async (eventId: string, eventData: any) => {
  const time_zone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? defaultTimezone;
  const utc_offset = moment.utc().tz(time_zone).utcOffset() ?? 0;

  const { status } = await Axios({
    method: "PATCH",
    url: "/api/calendar/" + eventId,
    data: {
      ...eventData,
      time_zone,
      utc_offset,
    },
  });

  if (status !== 201 && status !== 200) {
    return false;
  }

  return true;
};

const apiRemoveCalendarEvent = async (eventId: string) => {
  await Axios.delete(`/api/calendar/${eventId}`);
  return true;
};

const apiRequestUpdatePatientApp = async (patientId: string) => {
  await Axios({
    url: "/api/utility/autoupdate",
    method: "post",
    data: {
      version: null,
      patients: [patientId],
    },
  });

  return true;
}; 

const apiGetPatientsDevices = async () => {
  const result = await Axios.get("/api/device");
  if (result.status !== 200 || !result.data) {
    throw new Error("error getting devices data");    
  }

  return result.data as PatientDeviceType[];
};

const apiGetPatientDevice = async (deviceId: string) => {
  const result = await Axios.get(`/api/device/${deviceId}`);
  if (result.status !== 200 || !result.data) {
    throw new Error("error getting patient device");    
  }

  return result.data as PatientDeviceType;
};

const apiCreateDevice = async (deviceName: string) => {
  const result = await Axios.post("/api/device/create", { deviceName });
  
  if (result.status !== 201 || !result.data) {
    throw new Error("Error updating patient device name");    
  };

  return result;
};

const apiGetAccountUsers = async () => {
  const result = await Axios.get("/api/account/users");

  if (result.status !== 200 || !result.data) {
    throw new Error("error getting patient device");    
  }

  return result.data?.data?.users as UserType[] ?? [];
}

const apiRequestConnectDevice = async (deviceId: string) => {
  const result = await Axios.get(`/api/device/${deviceId}/conect`);

  if (result.status !== 200 || !result.data) {
    throw new Error("error getting patient device");    
  }

  return result.data;
};

const apiSetPatientDeviceName = async (deviceId: string, name: string) => {
  const result = await Axios.post(`/api/device/${deviceId}/name`, { name });
  if (result.status !== 201 || !result.data) {
    throw new Error("Error updating patient device name");    
  }

  return true;
};

const apiRemovePatientDevice = async (deviceId: string) => {
  const result = await Axios.delete(`/api/device/${deviceId}`);
  if (result.status !== 200 || !result.data) {
    throw new Error("Error updating patient device name");
  }

  return true;
};

const apiGetAllAvailablePatients = async () => {
  const result = await Axios.get("/api/patient/my");
  if (result.status !== 200 || !result.data) {
    throw new Error("error getting patients data");    
  }

  return result.data as PatientType[];
};

const apiRequestUpdateDevice = async (deviceId: string) => {
  const result = await Axios.post(`/api/device/${deviceId}/update`);
  if (result.status !== 200 || !result.data) {
    throw new Error("error updating patient device");    
  }

  return result.data as boolean;   
};

export {
  apiRequestConnectDevice,
  apiGetAccountUsers,
  apiCreateDevice,
  apiRequestUpdateDevice,
  apiRemovePatientDevice,
  apiGetPatientDevice,
  apiSetPatientDeviceName,
  apiGetAllAvailablePatients,
  apiGetPatientsDevices,
  apiRequestUpdatePatientApp,
  apiRemoveMediaResourceBlob,
  apiCreateMediaResourceBlobUploadSasUrl,
  apiUploadMediaResourceBlobBySasUrl,
  apiCreateMediaResource,
  apiUpdateMediaResource,
  apiGetPatientCalendarEvents,
  apiCreatePatientCalendarEvent,
  apiUpdatePatientCalendarEvent,
  apiRemoveCalendarEvent,
  apiImportMediaLibraryContent,
  apiAddPlaylistItems,
  apiGetMediaLibraryCategories,
  apiGetMediaLibraryResources,
  apiGetPatientSettings,
  apiUpdatePatientSettings,
};
