import {
  FC,
  memo,
  useCallback,
  useRef,
  useState,
} from "react";
import { t } from "i18next";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import Dropzone from "../../../common/Dropzone";
import { MediaContentDataType } from "@/types";
import AddContentFormSchema from "./AddContentFormSchema";
import { getFilesMediaType, getMockFileFromBlobData, getMockFileFromContent } from "./helpers";
import dialogUiConfig from "./dialogUiConfig";
import DialogCommonFooter from "../DialogCommonFooter";
import TagsMultiSelect from "@/components/common/TagsMultiSelect";
import {
  // apiCompleteUploadMediaResourceBlob,
  apiCreateMediaResource,
  apiCreateMediaResourceBlobUploadSasUrl,
  apiRemoveMediaResourceBlob,
  apiUpdateMediaResource,
  apiUploadMediaResourceBlobBySasUrl
} from "@/api";
import { FFmpeg, ProgressEvent } from "@ffmpeg/ffmpeg";
// import { FFmpeg } from "@ffmpeg/core-mt";
// const settings = require("../../../../../settings.json");
import { toBlobURL } from "@ffmpeg/util";

export type AddContentDialogPropsType = {
  onOpenClose: (isOpen: boolean) => void;
  apiPrefix: string;
  parentCategoryId?: string;
  selectedContent?: MediaContentDataType,
  onSubmit: (errMsg: string | null, successMsg?: string) => void;
  disabled: boolean;
};

const AddContentDialog: FC<AddContentDialogPropsType> = ({
  onOpenClose,
  apiPrefix,
  parentCategoryId,
  selectedContent,
  onSubmit,
  disabled,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitFetching, setSubmitFetching] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  // когда осуществлять загрузку ffmpeg?
  const ffmpegInst = useRef<FFmpeg|null>(null);
  const statusMsgRef = useRef<HTMLSpanElement|null>(null);

  const preventCloseByEsc = useCallback((e: any) => {
    if (dialogOpen && e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
    }
  }, [dialogOpen]);

  const onOpenChange = useCallback((open: boolean) => {
    setDialogOpen(open);

    if (!open && ffmpegInst.current?.loaded) {
      ffmpegInst.current.terminate();
      ffmpegInst.current = null;
    }

    if (onOpenClose) {
      onOpenClose(open);
    }
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<z.infer<typeof AddContentFormSchema>>({
    resolver: zodResolver(AddContentFormSchema),
    defaultValues: {
      fileName: selectedContent?.name ?? "",
      tags: selectedContent?.tags ?? [],
      file: getMockFileFromContent(selectedContent), // !TODO
      alt_file: selectedContent?.alt_media
        ? getMockFileFromBlobData(
            selectedContent.alt_media.blob_name,
            selectedContent.alt_media.metadata
          )
        : [], 
    },
  });

  const uiConfig = !!selectedContent
    ? dialogUiConfig.updateContext
    : dialogUiConfig.createContext;

  const loadFFmpeg = async (): Promise<FFmpeg | null> => {
    try {
      // const { assets_storage_url } = settings[process.env.NODE_ENV as string];
      // const baseURL = 'https://unpkg.com/@ffmpeg/core-mt@0.12.9/dist/umd'; // core/esm
      const baseURL = 'https://cdn.jsdelivr.net/npm/@ffmpeg/core-mt@0.12.9/dist/umd';
      const ffmpeg = new FFmpeg();
    
      // toBlobURL is used to bypass CORS issue,
      await ffmpeg.load({
        coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
        wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm"),
        workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
        // coreURL: await toBlobURL(`${assets_storage_url}/scripts/ffmpeg-core.js`, "text/javascript"),
        // wasmURL: await toBlobURL(`${assets_storage_url}/scripts/ffmpeg-core.wasm`, "application/wasm"),
        // workerURL: await toBlobURL(`${assets_storage_url}/scripts/ffmpeg-core.worker.js`, 'text/javascript'),
      });

      if (!ffmpeg.loaded) {
        throw new Error("error loading ffmpeg");
      }

      return ffmpeg;
    } catch (err) {
      console.log(err); // !DEBUG
      return null;
    }
  };

  const compressFile = async (
    ffmpeg: FFmpeg,
    file: File,
    onProgress: ((event: ProgressEvent) => void)
  ) => {
    try {

      ffmpeg.on("log", (data) => {
        console.log("[FFMPEG::log]... ", data.type, data.message); // !DEBUG
      });

      const fileBuffer = await file.arrayBuffer();
      const writeFileResult = await ffmpeg.writeFile("input.mp4", new Uint8Array(fileBuffer));

      ffmpeg.on("progress", onProgress);

      if (!writeFileResult) {
        throw new Error(`Error writing file: ${file.name}`);
      }

      const numChreadsToUse = "1"; // `${Math.floor(navigator.hardwareConcurrency / 2) || 1}`;
      const commands = [
        "-i", "input.mp4",
        "-c:v", "libx265",
        "-f", "mp4",
        "-c:a", "copy",
        // "-s", "1280x720",
        "-b:v", "2000k",
        "-crf", "30",
        "-preset", "medium",
        "-vf", "fps=25",
        "-profile:v", "main",
        "-level", "4.0",
        "-tag:v", "hvc1",
        "-pix_fmt", "yuv420p",
        "-threads", numChreadsToUse, // ?is required for the correct MT-usage?
        "output.mp4"
      ];

      await ffmpeg.ffprobe(["-v", "error", "-select_streams", "v:0", "-show_entries", "stream=width,height", "-of", "json", "input.mp4"]);

      // how to calculate threads count
      const execResult = await ffmpeg.exec(commands);

      if (execResult !== 0) {
        throw new Error(`Error compressing file: ${file.name}`);
      }

      const data = await ffmpeg.readFile("output.mp4");

      if (!ArrayBuffer.isView(data) || data.constructor !== Uint8Array) {
        throw new Error(`Error reading compressed file: ${file.name}`);
      }

      const resultFile = new File([data], file.name, {
        type: "video/mp4",
        lastModified: Date.now(),
      });

      return resultFile;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      ffmpeg.off("progress", onProgress);
    }
  };

  const checkIfMp4File = (file: File): boolean => {
    return file.type === "video/mp4" && file.name.split(".").at(-1) === "mp4";
  };

  const loadFFMpegAndCompressVideo = async (file: File): Promise<File|null> => {
    if (!ffmpegInst.current?.loaded) {
      ffmpegInst.current = await loadFFmpeg();

      if (!ffmpegInst.current) {
        throw new Error("error loading ffmpeg")
      }
    }

    const result = await compressFile(ffmpegInst.current, file, ({ progress, time }) => {
      const percent = Math.floor(progress * 100);

      if (statusMsgRef.current) {
        statusMsgRef.current.innerText = percent !== 100
          ? t("mediaLibraryScreen.addContentDialog.compressProgressMsg", { percent, name: file.name })
          : t("mediaLibraryScreen.addContentDialog.compressFinishedMsg", { name: file.name });
      }
    });

    return result;
  };

  const processFileToStorage = async(
    file: File,
    mediaResourceId: string,
    parentCategoryId: string,
    compressVideo: boolean,
    onUploadFileProgess: (fileName: string, percent: number) => void,
    blobNamePrefix?: string,
  ) => {
    let uploadCompressedBlobResult = null;
    const fileCompressed = (compressVideo && checkIfMp4File(file))
      ? await loadFFMpegAndCompressVideo(file)
      : null;

    if (fileCompressed) {
      uploadCompressedBlobResult = await apiCreateMediaResourceBlobUploadSasUrl(
        apiPrefix,
        mediaResourceId,
        parentCategoryId,
        fileCompressed.name,
        blobNamePrefix,
      );

      await apiUploadMediaResourceBlobBySasUrl(
        uploadCompressedBlobResult.blobUploadSasUrl,
        fileCompressed,
        ({ progress }) => onUploadFileProgess(fileCompressed.name, Math.floor(progress ?? 0 * 100)),
      );
    }

    const origFileName = uploadCompressedBlobResult
      ? `${file.name}.orig`
      : file.name;

    const uploadBlobResult = await apiCreateMediaResourceBlobUploadSasUrl(
      apiPrefix,
      mediaResourceId,
      parentCategoryId,
      origFileName,
      blobNamePrefix,
    );

    await apiUploadMediaResourceBlobBySasUrl(
      uploadBlobResult.blobUploadSasUrl,
      file,
      ({ progress }) => onUploadFileProgess(origFileName, Math.floor(progress ?? 0 * 100)),
    );

    return  [uploadBlobResult, uploadCompressedBlobResult];
  };

  const onUploadProgress = (name: string, percent: number) => {
    if (statusMsgRef.current) {
      statusMsgRef.current.innerText = percent !== 100
        ? t("mediaLibraryScreen.addContentDialog.uploadProgressMsg", { percent, name })
        : t("mediaLibraryScreen.addContentDialog.uploadFinishedMsg", { name });
    }
  };

  const onSubmitForm = async (formData: z.infer<typeof AddContentFormSchema>) => {

    try {
      if (submitFetching) {
        return;
      }
      setSubmitFetching(true);

      let formFile = formData.file[0];
      let formAltFile = formData.alt_file[0];

      // is always changed if new resource is creating
      const fileChanged = selectedContent ? formFile.lastModified !== 0 : true;
      const altFileChanged = formAltFile && formAltFile.lastModified !== 0;

      const media_type = (selectedContent && !fileChanged)
        ? selectedContent.media_type
        : getFilesMediaType(formData.file);

      if (!media_type) {
        throw new Error("Invalid media type");
      }

      // create new media resource (if needed) or use the existing one
      const mediaResourceUsed = selectedContent || await apiCreateMediaResource(
        apiPrefix,
        parentCategoryId ?? "root",
        {
          tags: formData.tags,
          category_id: parentCategoryId,
          name: formData.fileName,
          media_type,
        }
      );

      const [uploadMainBlobResult, uploadCompressedMainBlobResult] = fileChanged
        ? await processFileToStorage(formFile, mediaResourceUsed.id, parentCategoryId ?? "root", true, onUploadProgress)
        : [];
      const [uploadAltBlobResult, uploadCompressedAltBlobResult] = altFileChanged
        ? await processFileToStorage(formAltFile, mediaResourceUsed.id, parentCategoryId ?? "root", true, onUploadProgress, "alt_")
        : [];

      // remove alternative media blob if needed !TODO: remove compressed
      let removaAltBlobResult = false;

      // !TODO: helper function
      if (!formAltFile && selectedContent?.alt_media?.blob_name) {
        removaAltBlobResult = await apiRemoveMediaResourceBlob(
          apiPrefix,
          mediaResourceUsed.id,
          parentCategoryId ?? "root",
          selectedContent.alt_media.blob_name
        );

        if (removaAltBlobResult && selectedContent?.alt_media?.orig_blob_name) {
           await apiRemoveMediaResourceBlob(
            apiPrefix,
            mediaResourceUsed.id,
            parentCategoryId ?? "root",
            selectedContent.alt_media.orig_blob_name
          );
        }
      }

      // update an existing media resource (tags/name/blobs information)
      await apiUpdateMediaResource(
        apiPrefix,
        mediaResourceUsed.id,
        parentCategoryId ?? "root",
        {
          category_id: parentCategoryId,
          name: formData.fileName,
          tags: formData.tags,
          media_type,
          // main blob was changed
          ...(uploadMainBlobResult && {
            blob_name: uploadCompressedMainBlobResult
              ? uploadCompressedMainBlobResult.blobName
              : uploadMainBlobResult.blobName, 
            blob_url: uploadCompressedMainBlobResult
              ? uploadCompressedMainBlobResult.blobPublicSasUrl
              : uploadMainBlobResult.blobPublicSasUrl, 
            ...(uploadCompressedMainBlobResult && {
              orig_blob_url: uploadMainBlobResult.blobPublicSasUrl,
              orig_blob_name: uploadMainBlobResult.blobName,
            }),
            metadata: {
              fileName: formFile.name,
              fileSize: formFile.size,
              fileType: formFile.type,
            },
          }),
          ...(uploadAltBlobResult && {
            alt_media: {
              media_type,
              blob_name: uploadCompressedAltBlobResult
                ? uploadCompressedAltBlobResult.blobName
                : uploadAltBlobResult.blobName,
              blob_url: uploadCompressedAltBlobResult
                ? uploadCompressedAltBlobResult.blobPublicSasUrl
                : uploadAltBlobResult.blobPublicSasUrl, 
              ...(uploadCompressedAltBlobResult && {
                orig_blob_url: uploadAltBlobResult.blobPublicSasUrl,
                orig_blob_name: uploadAltBlobResult.blobName,
              }), 
              metadata: {
                fileName: formAltFile.name,
                fileSize: formAltFile.size,
                fileType: formAltFile.type,
              }            
            }
          }),
          ...(removaAltBlobResult && { alt_media: null })
        },
      );
      // !TODO: media resource created
    } catch (err) {
      console.error(err);
      onSubmit(uiConfig.submitErrorMessage);
    } finally {
      setSubmitFetching(false)
    }

    onOpenChange(false);
    onSubmit(null, uiConfig.submitSuccessMessage);
    return;
  };

  const avoidDefaultDomBehavior = useCallback((e: Event) => {
    e.preventDefault() 
  }, []);

  // for testing
  // const videoRef = useRef<HTMLVideoElement | null>(null);

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={onOpenChange}
    >
      <DialogTrigger
        disabled={disabled}
        className="p-0 w-full"
      >
        <DropdownMenuItem
          disabled={disabled}
          className="w-full flex py-0 px-[8px]"
          onSelect={(e) => e.preventDefault()}
        >
          <div className="flex flex-row items-center w-full h-[32px]">
            <uiConfig.DialogTriggerIcon className="h-4 w-4 mr-[8px]" />
            <span>{uiConfig.dialogTriggerLabel}</span>
          </div>
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent
        hideDefaultCloseBtn
        onEscapeKeyDown={submitFetching ? preventCloseByEsc : undefined}
        onPointerDownOutside={popoverOpen || submitFetching ? avoidDefaultDomBehavior : undefined}
        onInteractOutside={popoverOpen || submitFetching ? avoidDefaultDomBehavior : undefined}
        className="flex flex-col w-[436px]"
      >
        <DialogHeader>
          <DialogTitle>
            {uiConfig.dialogHeaderTitle}
          </DialogTitle>
          <DialogDescription>
            {uiConfig.dialogHeaderDescr}
          </DialogDescription>
        </DialogHeader>
        {/* !TEST for video preview */}
        {/* <div className="h-0 w-full border-2 border-slate-250">
          <video ref={videoRef} controls className="w-full h-full" />
        </div> */}
        <Form {...form}>
          <form
            className="h-full"
            onSubmit={form.handleSubmit(onSubmitForm)}
          >
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="fileName"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel
                    className="mb-[6px]"
                    htmlFor="filename"
                  >
                    {dialogUiConfig.nameFieldLabel}
                  </FormLabel>
                  <FormControl id="filename">
                    <Input
                      className="focus-visible:ring-0"
                      id="filename"
                      placeholder={dialogUiConfig.nameFieldPlaceholder}
                      {...field}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              disabled={submitFetching}
              name="tags"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel className="mb-[6px]" htmlFor="tags">
                    {dialogUiConfig.tagsFieldLabel}
                  </FormLabel>
                  <FormControl id="tags">
                    <Controller
                      {...field}
                      render={({ field: { onChange, onBlur, value }}) => (
                        <TagsMultiSelect
                          onOpenClose={setPopoverOpen}
                          // !FIXME
                          placeholder={t("mediaLibraryScreen.tagsPlaceholder")}
                          defaultSelectedValues={value}
                          disabled={submitFetching}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              name="file"
              render={({ field: { value, onChange, ...fieldProps } }) => {
                return (
                  <FormItem className="mb-1">
                    <FormControl>
                      <Dropzone
                        disabled={submitFetching}
                        {...fieldProps}
                        acceptedFiles={form.watch('file')}
                        // !TODO
                        onRemoveFile={(fileToRemove: File) => {
                          form.setValue(
                            "file",
                            form.getValues("file").filter((file: File) => file !== fileToRemove)
                          );

                          form.setValue("alt_file", []);
                        }}
                        onChange={onChange}
                      />
                    </FormControl>
                    <div className="flex h-[20px] overflow-hidden">
                      <FormMessage className="truncate" />
                    </div>
                  </FormItem>
                );
              }}
            />
            {(form.watch("file").at(0)?.type === "video/mp4") && (
              <FormField
                name="alt_file"
                render={({ field: { value, onChange, ...fieldProps } }) => (
                  <FormItem>
                    <FormControl>
                      <Dropzone
                        {...fieldProps}
                        disabled={submitFetching}
                        acceptedFiles={form.watch("alt_file")}
                        onChange={onChange}
                        // !TODO
                        onRemoveFile={(fileToRemove: File) => {
                          form.setValue(
                            "alt_file",
                            form.getValues("alt_file").filter((file: File) => file !== fileToRemove)
                          );
                        }}
                        mimeTypesAllowed={["video/mp4"]}
                        dropAreaLabel="Additional video to display in portrait mode" // !TODO: label
                      />
                    </FormControl>
                    {/* <div className="flex h-[20px] overflow-hidden">
                      <FormMessage className="truncate" />
                    </div> */}
                  </FormItem>
                )}
              />
            )}
          </form>
        </Form>
        <div className="overflow-hidden pe-4 items-center flex justify-center border-0 border-slate-200 h-8">
          <span
            className="font-normal text-sm text-[#11203D] align-start italic whitespace-nowrap truncate"
            ref={statusMsgRef}
          >
            {/* Compressing file (20%): longfilenae lingfilename long file name1 name2 name3 name4 */}
          </span>
        </div>

        <DialogCommonFooter
          isFetching={submitFetching}
          onSubmitBtnClick={form.handleSubmit(onSubmitForm)}
          submitBtnLabel={uiConfig.submitBtnLabel}
          cancelBtnLabel={t("common.cancel")}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddContentDialog);
