import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { AppContext } from "app/providers/stateProvider";
import { Icon } from "../../icon/icon";
import { useRoomConnection, VideoView } from "@whereby.com/browser-sdk/react";
import { MicIcon, MicOffIcon, CameraIcon, CameraOffIcon, PhoneOffIcon } from "lucide-react";
import { useToast } from "../../use-toast";
import { t } from "i18next";
import http from "http";
import https from "https";

const httpAgent = new http.Agent({ keepAlive: true });
const httpsAgent = new https.Agent({ keepAlive: true });

function VideoCall({ roomUrl, onCallFinish }) {
  const roomConnection = useRoomConnection(roomUrl, {
    localMediaOptions: {
      audio: true,
      video: true,
    },
  });
  const [isCameraActive, setIsCameraActive] = useState(true);
  const [isMicrophoneActive, setIsMicrophoneActive] = useState(true);
  const { actions, state, events } = roomConnection;
  const { localParticipant, remoteParticipants } = state;
  const isMounted = useRef(false);

  useEffect(() => {
    const handleEvents = (e) => console.log(e);
    events.on("*", handleEvents);
    actions.joinRoom();

    return () => {
      events.off("*", handleEvents);
      actions.leaveRoom();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMounted.current && state.connectionStatus === "kicked") {
      onCallFinish(true);
    }
    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.connectionStatus]);

  const onToggleMicrophone = () => {
    actions.toggleMicrophone();
    setIsMicrophoneActive((prev) => !prev);
  };

  const onToggleCamera = () => {
    actions.toggleCamera();
    setIsCameraActive((prev) => !prev);
  };

  const MicIconUsed = isMicrophoneActive ? MicIcon : MicOffIcon;
  const CamIconUsed = isCameraActive ? CameraIcon : CameraOffIcon;
  const firstRemoteParticipant = remoteParticipants.at(0);

  return (
    <div className="flex flex-1 relative items-center justify-center">
      {/* participant identifier */}
      {/* {remoteParticipants.at(0) && (
        <div className="absolute top-4 left-4 z-1 rounded-sm p-2 bg-[#ffffff] bg-opacity-40">
          <p className="text-sm text-[#11203D] font-medium">id: {remoteParticipants.at(0).id}</p>
        </div>
      )} */}
      {["ready", "connecting", "reconnecting"].includes(state.connectionStatus) && (
        <p className="text-md text-[#11203D] font-medium">
          {t("patientScreen.callDialog.statusConnecting")}
        </p>
      )}
      {(state.connectionStatus === "connected" && !firstRemoteParticipant) && (
        <p className="text-md text-[#11203D] font-medium">
          {t("patientScreen.callDialog.statusWaiting")}
        </p>
      )}
      {(state.connectionStatus === "kicked") && (
        <p className="text-md text-[#11203D] font-medium">
          {t("patientScreen.callDialog.statusFinished")}
        </p>
      )}
      {(state.connectionStatus === "connected" && firstRemoteParticipant) && (
        <VideoView
          className="!w-auto"
          style={{ backgroundColor: "#250f51", width: "auto !important" }}
          stream={firstRemoteParticipant.stream}
          crossOrigin="anonymous"
        />
      )}
      {(state.connectionStatus === "connected") && (
        <div className="flex flex-row py-2 bottom-2 absolute border-0 border-red-500 w-full justify-center items-center z-10">
          <button
            className="mx-2 whitespace-nowrap ring-offset-background focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 hover:text-accent-foreground inline-flex h-20 w-20 items-center justify-center rounded-full bg-orange-400 transition-colors hover:bg-orange-400/90 focus:outline-none focus:ring-0 focus:ring-orange-500 focus:ring-offset-0 dark:bg-orange-50 dark:hover:bg-orange-50/90 dark:focus:ring-orange-300"
            onClick={onToggleCamera}>
            <CamIconUsed color="#ffffff" className="h-12 w-12" />
          </button>
          <button
            className="mx-2 whitespace-nowrap ring-offset-background focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 hover:text-accent-foreground inline-flex h-20 w-20 items-center justify-center rounded-full bg-orange-400 transition-colors hover:bg-orange-400/90 focus:outline-none focus:ring-0 focus:ring-orange-500 focus:ring-offset-0 dark:bg-orange-50 dark:hover:bg-orange-50/90 dark:focus:ring-orange-300"
            onClick={onToggleMicrophone}>
            <MicIconUsed color="#ffffff" className="h-12 w-12" />
          </button>
          <button
            className="mx-2 whitespace-nowrap ring-offset-background focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 hover:text-accent-foreground inline-flex h-20 w-20 items-center justify-center rounded-full bg-red-400 transition-colors hover:bg-red-400/90 focus:outline-none focus:ring-0 focus:ring-red-500 focus:ring-offset-0 dark:bg-red-50 dark:hover:bg-red-50/90 dark:focus:ring-red-300"
            onClick={() => onCallFinish(false)}
          >
            <PhoneOffIcon color="#ffffff" className="h-12 w-12" />
          </button>
        </div>
      )}
      {localParticipant?.stream && (
        <div className="absolute h-32 right-2 bottom-2 z-10">
          <VideoView
            className="!w-auto !rounded-md"
            stream={localParticipant.stream}
          />
          <div className="absolute top-2 right-2 p-1 rounded-sm bg-[#ffffff] bg-opacity-40">
            <p className="text-xs text-[#11203D] font-light">
            {t("patientScreen.callDialog.selfLabel")}
            </p>
          </div>
          <div className="absolute flex flex-1 w-full top-2 left-2">
            {!isCameraActive && (<CamIconUsed color="orange" className="h-4 w-4 mx-1" />)}
            {!isMicrophoneActive && (<MicIconUsed color="orange" className="h-4 w-4 mx-1" />)}
          </div>
        </div>
      )}
    </div>
  );
};

export function CallDialog(props) {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const [callInfo, setCallInfo] = useState(props.callInfo);
  const iframeRef = useRef(null);
  const { toast } = useToast();
  const [dialogOpen, setDialogOpen] = useState(false);

  // useEffect(() => {
  //   setCallInfo(props?.callInfo);
  // }, [props?.callInfo]);

  useEffect(() => {
    if (!isSafari() && callInfo && iframeRef.current) {
      iframeRef.current.credentialless = true;
      iframeRef.current.src = callInfo?.roomUrl;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callInfo]);

  function renderCall() {
    return isSafari() ? (
      <VideoCall
        onCallFinish={(finishedByRecipient = false) => {
          if (callInfo) {
            setDialogOpen(false);
            toast({
              title: t("patientScreen.callDialog.statusFinished"),
              variant: "default"
            })
          }
        }}
        roomUrl={callInfo.roomUrl}
      />
    ) : (
      <iframe
        ref={iframeRef}
        title="call"
        className="w-full h-full"
        allow="camera; microphone; speaker; fullscreen; display-capture"
      />
    );
  }

  function renderCalling() {
    return (
      <span>
        {t("patientsScreen.profile.calling")} {props.patientName}
      </span>
    );
  }

  async function startCall() {
    const callInfoRespose = await Axios.post("/api/call", {
      patient: props.patientId,
      originatorName:
        appContext?.chat?.chatState?.currentUserData?.name || "test",
    });

    setCallInfo({ ...callInfoRespose.data, callAccepted: true });
  }

  async function finishCall() {
    if (callInfo) {
      await Axios.post("/api/call/" + callInfo?.roomId + "/finish", callInfo, {
        httpAgent,
        httpsAgent
      });
    }
  }

  // async function acceptCall() {
  //   console.log("call accepted", callInfo);
  //   const response = await Axios.post(
  //     "/api/call/" + callInfo?.roomId + "/accept",
  //     callInfo
  //   );
  //   setCallInfo(response.data);
  // }

  useEffect(() => {
    if (dialogOpen) {
      startCall();
    } else {
      finishCall();
      setCallInfo(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  useEffect(() => {
    window.addEventListener("beforeunload", finishCall);
    return () => {
      finishCall();
      window.removeEventListener("beforeunload", finishCall);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callInfo]);

  const isSafari = useCallback(() => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }, []);

  const preventCloseByEsc = useCallback((e) => {
    if (dialogOpen && e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
    }
  }, [dialogOpen]);

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={setDialogOpen}
    >
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full bg-[#13974F] hover:bg-[#13974F]/80"
        >
          <Icon
            image="icon-call"
            pack="innocom"
            color="#fff"
            className="text-[20px]"
          />
        </Button>
      </DialogTrigger>
      <DialogContent
        onEscapeKeyDown={isSafari() ? preventCloseByEsc : undefined}
        hideDefaultCloseBtn={isSafari()}
        className="min-w-full h-screen"
      >
        <div className="flex h-full p-2">
          {callInfo?.callAccepted
            ? renderCall()
            : renderCalling()}
        </div>
      </DialogContent>
    </Dialog>
  );
}
