import { FC, memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../dialog";
import { MediaContentDataType } from "@/types";
import { ArrowRight, ArrowLeft } from "lucide-react";
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = "https://unpkg.com/pdfjs-dist@4.10.38/build/pdf.worker.min.mjs";

type PreviewContentDialogPropsType = {
  triggerStyle?: string;
  selectedMediaContent?: MediaContentDataType | null;
  setSelectedMediaContent: (entityData: MediaContentDataType | null) => void;
};

const PreviewContentDialog: FC<PreviewContentDialogPropsType> = ({
  selectedMediaContent,
  setSelectedMediaContent,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const pdfDocInst = useRef<pdfjsLib.PDFDocumentProxy|null>(null);
  const selectedPageNum = useRef<number>(1);
  const [iframeStateRef, setIframeStateRef] = useState<any|null>(null);
  const pageRendering = useRef<boolean>(false);
  const pageNumPending = useRef<number|null>(null);

  const isDocument = selectedMediaContent && (
    selectedMediaContent.media_type === "document"
      || (selectedMediaContent.media_type === "shared_resource"
      && selectedMediaContent.original_media_type === "document")
  );

  const isVideo = selectedMediaContent && (
    selectedMediaContent.media_type === "video"
      || (selectedMediaContent.media_type === "shared_resource"
      && selectedMediaContent.original_media_type === "video")
  );

  const isExternalSource = selectedMediaContent && (
    selectedMediaContent.media_type === "external_source"
      || (selectedMediaContent.media_type === "shared_resource"
      && selectedMediaContent.original_media_type === "external_source")
  );

  useLayoutEffect(() => {
    if (isExternalSource && iframeStateRef) {
      iframeStateRef.credentialless = true;
      iframeStateRef.src = `${selectedMediaContent.metadata?.link}#toolbar=0&navpanes=0`;
    }
  }, [isExternalSource, selectedMediaContent, iframeStateRef]);

  useEffect(() => {
    if (!isDocument || !selectedMediaContent?.blob_url) return;

    (async() => {
      const response = await fetch(selectedMediaContent.blob_url as string); // !FIXME
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      pdfDocInst.current = await pdfjsLib.getDocument(objectUrl).promise;
      if (pdfDocInst.current && canvasRef.current) {
        renderPdfPage(selectedPageNum.current, pdfDocInst.current, canvasRef.current);
      }      
    })();

    const pdfDoc = pdfDocInst.current;

    return () => {
      if (pdfDoc) {
        pdfDoc.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMediaContent, isDocument]);

  const renderPdfPage = async (
    pageNum: number,
    pdfDoc: pdfjsLib.PDFDocumentProxy,
    canvas: HTMLCanvasElement
  ) => {
    try {
      pageRendering.current = true;
      if (pageNum <= 0 || pageNum > pdfDoc.numPages) return;
      const page = await pdfDoc.getPage(pageNum);
      const viewport = page.getViewport({ scale: 0.8 });
      const canvasContext = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      if (!canvasContext) {
        throw new Error("error getting canvas context");
      }

      const renderTask = page.render({ canvasContext, viewport });
      await renderTask.promise;
      pageRendering.current = false;

      if (pageNumPending.current !== null) {
        renderPdfPage(pageNumPending.current, pdfDoc, canvas);
        pageNumPending.current = null;
      }
    } catch (error) {
      console.error("error rendering page: ", error);
    }
  };

  const queueRenderPage = (
    pageNum: number,
    pdfDoc: pdfjsLib.PDFDocumentProxy,
    canvas: HTMLCanvasElement
  ) => {
    if (pageRendering.current) {
      pageNumPending.current = pageNum;
    } else {
      renderPdfPage(pageNum, pdfDoc, canvas);
    }
  } 

  return (
    <Dialog
      open={!!selectedMediaContent}
      onOpenChange={(open) => {
        if (!open) {
          setSelectedMediaContent(null);
        }
      }}
    >
      <DialogContent
        hideDefaultCloseBtn
        className="flex flex-col w-10/12 sm:max-w-full sm:max-h-full h-4/6 overflow-hidden"
        // className="flex flex-col w-fit max-w-fit h-fit max-h-fit overflow-hidden"
      >
        <DialogHeader>
          <DialogTitle>
            {selectedMediaContent?.name}
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-1 flex-col overflow-hidden">
          {/* === display document === */}
          {isDocument && (
            <div className="relative w-full h-full flex flex-1 items-center justify-center">
              <canvas
                className="h-full w-auto"
                ref={canvasRef}
              />
              <div className="flex absolute bottom-0 py-2 mt-2 items-center justify-center">
                <button
                  className="border-2 border-stone-50 mx-2 whitespace-nowrap ring-offset-background focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 hover:text-accent-foreground inline-flex h-12 w-12 items-center justify-center rounded-full bg-orange-400 transition-colors hover:bg-orange-400/90 focus:outline-none focus:ring-0 focus:ring-orange-500 focus:ring-offset-0 dark:bg-orange-50 dark:hover:bg-orange-50/90 dark:focus:ring-orange-300"
                  onClick={() => {
                    if (pdfDocInst.current && canvasRef.current && selectedPageNum.current - 1 > 0) {
                      selectedPageNum.current = selectedPageNum.current - 1;
                      // renderPdfPage(selectedPageNum.current, pdfDocInst.current, canvasRef.current);
                      queueRenderPage(selectedPageNum.current, pdfDocInst.current, canvasRef.current);
                    }
                }}>
                  <ArrowLeft color="#ffffff" className="h-8 w-8" />
                </button>
                <button
                  className="border-2 border-stone-50 mx-2 whitespace-nowrap ring-offset-background focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 hover:text-accent-foreground inline-flex h-12 w-12 items-center justify-center rounded-full bg-orange-400 transition-colors hover:bg-orange-400/90 focus:outline-none focus:ring-0 focus:ring-orange-500 focus:ring-offset-0 dark:bg-orange-50 dark:hover:bg-orange-50/90 dark:focus:ring-orange-300"
                  onClick={() => {
                    if (pdfDocInst.current && canvasRef.current && selectedPageNum.current + 1 <= pdfDocInst.current?.numPages) {
                      selectedPageNum.current = selectedPageNum.current + 1;
                      // renderPdfPage(selectedPageNum.current, pdfDocInst.current, canvasRef.current);
                      queueRenderPage(selectedPageNum.current, pdfDocInst.current, canvasRef.current);
                    }
                }}>
                  <ArrowRight color="#ffffff" className="h-8 w-8" />
                </button>
              </div>
            </div>
          )}
          {/* === display external source === */}
          {isExternalSource && (
            <iframe
              ref={(ref) => setIframeStateRef(ref)}
              allow="cross-origin-isolated"
              sandbox="allow-scripts allow-top-navigation"
              title="document"
              width="100%"
              height="100%"
            />
          )}
          {/* === display video === */}
          {isVideo && (
            <video
              crossOrigin="anonymous"
              width="100%"
              height="100%"
              controls
              controlsList="nodownload"
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              preload="none"
              style={{
                backgroundColor: "#cfcfcf",
                borderRadius: "4px",
                maxHeight: "100%",
                width: "100%",
                height: "100%",
              }}
            >
              <source
                src={selectedMediaContent.orig_blob_url || selectedMediaContent.blob_url}
                type="video/mp4"
              />
            </video>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(PreviewContentDialog);
