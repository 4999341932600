import { FC, memo, MouseEventHandler, ReactElement } from "react";
import { DialogClose, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Loader2 } from "lucide-react";

type DialogCommonFooterPropsType = {
  className?: string;
  submitBtnClassName?: string;
  closeBtnClassName?: string;
  isFetching: boolean;
  onSubmitBtnClick: MouseEventHandler<HTMLButtonElement>;
  submitBtnLabel: string;
  cancelBtnLabel: string;
  CustomControls?: ReactElement<any>
};

const DialogCommonFooter: FC<DialogCommonFooterPropsType> = ({
  className = "",
  submitBtnClassName = "",
  closeBtnClassName = "",
  isFetching,
  onSubmitBtnClick,
  submitBtnLabel,
  cancelBtnLabel,
  CustomControls
}) => {
  return (
    <DialogFooter>
      <div className={cn("w-full justify-between items-center flex", className)}>
        {CustomControls && CustomControls}
        <DialogClose disabled={isFetching}>
          <Button
            className={cn("focus-visible:ring-0 focus:ring-0 px-[16px]", closeBtnClassName)} 
            type="button"
            variant="ghost"
            disabled={isFetching}
          >
            {cancelBtnLabel}
          </Button>
        </DialogClose>
        <Button
          disabled={isFetching}
          className={cn("bg-[#1949A3] focus-visible:ring-0 px-[16px]", submitBtnClassName)}
          type="submit"
          onClick={onSubmitBtnClick}
        >
          {isFetching && (
            <Loader2
              color="#FFFFFF"
              className={cn("h-[16px] w-[16px] text-primary/60 animate-spin mr-[2px]")}
            />
          )}
          {submitBtnLabel}
        </Button>
      </div>
    </DialogFooter>
  );
};

export default memo(DialogCommonFooter);