import {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from "react";
import Axios from "axios";
import { ViewContext } from "components/lib";
import { useTranslation } from "react-i18next";
import { Loader2 } from "lucide-react";
import { AuthContext } from "app/providers/auth";
import { useError } from "app/utils";
import { useToast } from "../use-toast";
import { debounce } from "lodash";
import { CardContent, CardHeader } from "../card";
import { cn } from "@/lib/utils";
import { Input } from "../input";
import MediaContentEntity from "./MediaEntity/MediaContentEntity";
import MediaCategoryEntity from "./MediaEntity/MediaCategoryEntity";
import MediaLibraryBreadcrumbs from "./MediaEntity/MediaLibraryBreadcrumbs";
import MediaLibraryActionsDropdown from "../../common/dropdown/MediaLibraryActionsDropdown";
import MediaCategoryActionsDropdown from "../../common/dropdown/MediaCategoryActionsDropdown";
import MediaContentActionsDropdown from "../../common/dropdown/MediaContentActionsDropdown";
import { ScrollArea } from "../scroll-area";
import SortOrderSelector from "@/components/common/SortOrderSelector";
import CustomMultiSelect from "@/components/common/CustomMultiSelect";
import ActiveFilterValueTrigger from "@/components/common/ActiveFilterValueTrigger";
import {
  sortLabelsConfig,
  staticMediaContentTypeConfig,
} from "@/config";
import { createMediaLibContentSortFn } from "../dialog/AddPlaylistContentDialog/helpers";
import AddUpdatePackageDialog from "../dialog/AddUpdatePackageDialog";
import { apiGetMediaLibraryResources } from "@/api";
import TagsMultiSelect from "@/components/common/TagsMultiSelect";
import PreviewContentDialog from "../dialog/PreviewContentDialog";

// !TODO: typescript
const sortConfig = [
  { id: "sortByName", name: sortLabelsConfig.sortByName },
  { id: "sortByDate", name: sortLabelsConfig.sortByDate },
  { id: "sortByMediaType", name: sortLabelsConfig.sortByMediaType },
];

export function Categories(props) {
  const { apiPrefix, onOpenPlaylist, onChangeCategory } = props;
  const [selectedCategoryId, setSelectedCategoryId] = useState(props.categoryId);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const isPackage = props.package ?? false;
  const [searchString, setSearchString] = useState("");
  const isPrivateLibrary = props.privateLibrary ?? false;
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const context = useContext(ViewContext);
  const [categories, setCategories] = useState([]);
  const [content, setContent] = useState([]); // !TODO: rename: selectedCategoryContent
  const [searchResultContent, setSearchResultContent] = useState([]);
  const [sharedCategory, setSharedCategory] = useState(null);
  const [dataFetching, setDataFetching] = useState(false);
  const [handleError] = useError();
  const [sortOrder, setSortOrder] = useState(sortConfig[0].id);
  const [filterTags, setFilterTags] = useState([]);
  const [filterMediaTypes, setFilterMediaTypes] = useState([]);
  const inputRef = useRef(null);
  const { toast } = useToast();
  const [previewContentItem, setPreviewContentItem] = useState(null);

  // rename: ...to the opened library
  const hasWriteAccess =
    authContext.permission?.master ||
    authContext.permission?.owner ||
    authContext.permission?.admin ||
    (authContext.permission?.doctor && isPrivateLibrary);

  const deleteContent = async (row) => {
    if (
      !window.confirm(
        t("mediaLibraryScreen.contentForm.deleteConfirmation", {
          name: row.name,
        })
      )
    ) {
      return;
    }

    try {
      setDataFetching(true);
      await Axios.delete(
        `${apiPrefix}/category/${selectedCategoryId ?? "root"}/resource/${row.id}`
      );
      await refreshDataForSelectedCategory();
      toast({ title: t("mediaLibraryScreen.contentForm.deleteNotification") });
    } catch (error) {
      console.log(error);
      handleError(error);
    } finally {
      setDataFetching(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (searchString.length === 0
          && filterTags.length === 0
          && filterMediaTypes.length === 0) {
          return;
        }

        setDataFetching(true);

        const resourcesData = await apiGetMediaLibraryResources(apiPrefix, {
          name: searchString,
          tags: filterTags,
          media_type: filterMediaTypes,
        }, true);
  
        setSearchResultContent(
          resourcesData.filter(({ media_type }) => media_type !== "shared_category")
        );

      } catch (err) {
        console.error(err);
      } finally {
        setDataFetching(false);
        setTimeout(() => {
          inputRef?.current?.focus?.();          
        }, 150);
      }
    })();
  }, [
    apiPrefix,
    searchString,
    filterTags,
    filterMediaTypes,
  ]);

  const refreshDataForSelectedCategory = useCallback(async () => {
    try {
      setDataFetching(true);

      let endpoint = `${apiPrefix}/category`;
      if (selectedCategoryId) {
        endpoint += `/${selectedCategoryId}`;
      }

      const categoryEndpoint =
        sharedCategory === null
          ? endpoint
          : `${endpoint}?share=${sharedCategory}`;
      const categoriesData = await Axios.get(categoryEndpoint);

      if (!selectedCategoryId) {
        endpoint += "/root";
      }

      const resourcesData = (await Axios.get(`${endpoint}/resource`))?.data || [];
      const preparedContent = [];
      const sharedCategories = [];

      resourcesData.forEach((resourceDataEntry) => {
        if (resourceDataEntry.media_type === "shared_category") {
          sharedCategories.push(resourceDataEntry);
        } else {
          // FIXME
          resourceDataEntry.actions = {
            // edit: updateContent,
            // delete: deleteContent,
          };

          if (resourceDataEntry.blob_url) {
            resourceDataEntry.actions.download = resourceDataEntry.blob_url;
          }

          preparedContent.push(resourceDataEntry);
        }
      });

      if (selectedCategoryId) {
        setSelectedCategory(categoriesData.data);
        setCategories(sharedCategories.concat(categoriesData.data.children));
      } else {
        setSelectedCategory(null);
        setCategories(sharedCategories.concat(categoriesData.data));
      }

      if (!selectedCategoryId && isPackage) {
        setContent([]);
      } else {
        setContent(preparedContent);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataFetching(false);
    }
  }, [
    apiPrefix,
    selectedCategoryId,
    sharedCategory,
    isPackage,
  ]);

  useEffect(() => { refreshDataForSelectedCategory() }, [refreshDataForSelectedCategory]);

  // category actions
  const gotoCategory = (row) => {
    setSharedCategory(null);
    setSelectedCategoryId(row.id);
    onChangeCategory(row.id);
  };

  const deleteCategory = async (row) => {
    if (
      !window.confirm(
        t("mediaLibraryScreen.categoryForm.deleteConfirmation", {
          name: row.name,
        })
      )
    ) {
      return;
    }

    try {
      setDataFetching(true);
      await Axios.delete(`${apiPrefix}/category/${row.id}`);
      await refreshDataForSelectedCategory();
      toast({ title: t("mediaLibraryScreen.categoryForm.deleteNotification") });
    } catch (error) {
      console.log(error);
      handleError(error);
    } finally {
      setDataFetching(false);
    }
  };

  const openContentPreview = (content) => {
    if (
      content.media_type === "external_source" ||
      (content.media_type === "shared_resource"
        && content.original_media_type === "external_source")
    ) {
      setPreviewContentItem(content);
    } else if (
      content.media_type === "video" ||
      (content.media_type === "shared_resource" &&
        content.original_media_type === "video")
    ) {
      setPreviewContentItem(content);
      // return context.videoDialog.show(content.name, content.blob_url);
    } else if (
      content.media_type === "document" ||
      (content.media_type === "shared_resource" &&
        content.original_media_type === "document")
    ) {
      setPreviewContentItem(content);
      // return context.documentDialog.show(content.name, content.blob_url);

    } else if (content.media_type === "playlist") {
      onOpenPlaylist(content.id);
    } else if (content.media_type === "shared_category") {
      setSharedCategory(selectedCategory?.id ?? "root");
      setSelectedCategoryId(content.linked_resource_id);
      onChangeCategory(content.linked_resource_id);
    }
  };

  const onAddUpdateMediaLibData = (errMsg, successMsg) => {
    if (successMsg) {
      toast({ title: successMsg });
      refreshDataForSelectedCategory();
    } else {
      toast({
        title: errMsg,
        variant: "destructive",
      });
    }
  };

  const categoryItemsToDisplay = useMemo(() => {
    const mediaLibContentSortFn = createMediaLibContentSortFn(sortOrder);

    return categories
      .filter((categoryItem) => {
        return categoryItem?.name
          ?.toLowerCase()
          .includes(searchString.toLowerCase());
      })
      .sort(mediaLibContentSortFn);
  }, [categories, searchString, sortOrder]);

  const contentItemsToDisplay = useMemo(() => {
    const mediaLibContentSortFn = createMediaLibContentSortFn(sortOrder);

    const recentItems = (!filterTags?.length && !filterMediaTypes?.length && !searchString?.length)
      ? content
      : searchResultContent;

    return recentItems.sort(mediaLibContentSortFn);
  }, [
    searchResultContent,
    content,
    filterTags,
    filterMediaTypes,
    searchString,
    sortOrder
  ]);

  const canModifyLibraryContent = useMemo(() => {
    if (isPrivateLibrary) {
      return !selectedCategory || selectedCategory.user_id;
    }

    // admin doesnt have perm
    if (isPackage) {
      return authContext.permission?.master
        || authContext.permission?.owner;
    }

    // common library
    return (authContext.permission?.master
    || authContext.permission?.owner
    || authContext.permission?.admin) && !selectedCategory?.is_package;
  }, [
    selectedCategory,
    isPrivateLibrary,
    isPackage,
    authContext.permission,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchInputChange = useCallback(debounce((e) => {
    setSearchString(e.target.value);
  }, 650), []);

  const searchEnabled = filterTags.length !== 0
    || filterMediaTypes.length !== 0
    || searchString.length !== 0;

  return (
    <div className="flex flex-col h-full overflow-hidden px-2">
      <PreviewContentDialog
        selectedMediaContent={previewContentItem}
        setSelectedMediaContent={setPreviewContentItem}
      />
      {searchEnabled ? (
        <div className="flex min-h-12 h-12 items-center mb-2">
          <span className="text-lg font-semibold text-[#11203D]">
            {t("mediaLibraryScreen.searchResultsHeaderTitle")}
          </span>
        </div>
      ) : (
        <MediaLibraryBreadcrumbs
          category={selectedCategory}
          setCategoryId={(id) => {
            onChangeCategory(id);
            setSelectedCategoryId(id);
          }}
        />
      )}
      <ScrollArea className="h-full">
        <CardHeader className="flex flex-row justify-between items-center p-0 pe-4 pt-0 ">
          <div className="flex flex-row flex-1 items-center">
            <Input
              ref={inputRef}
              disabled={dataFetching}
              type="search"
              onChange={onSearchInputChange}
              placeholder={t("common.searchByName")}
              className="focus-visible:ring-0 max-w-xs"
            />
            <CustomMultiSelect
              placeholder={t("mediaLibraryScreen.mediaTypeFilterTriggerLabel")}
              defaultSelectedValues={[]}
              data={staticMediaContentTypeConfig}
              disabled={dataFetching}
              className="px-[10px] w-auto ml-[12px] border-dashed"
              onChange={setFilterMediaTypes}
              TriggerContentComponent={ActiveFilterValueTrigger}
              clearSelectionBtnLabel={t("mediaLibraryScreen.mediaTypeFilterClearBtn")}
            />
            <TagsMultiSelect
              className="px-[10px] w-auto ml-[12px] border-dashed"
              placeholder={t("mediaLibraryScreen.tagsPlaceholder")}
              defaultSelectedValues={filterTags}
              // tagsData={staticTagsConfig}
              disabled={dataFetching}
              onChange={setFilterTags}
              TriggerContentComponent={ActiveFilterValueTrigger}
            />
            {/* <CustomMultiSelect
              className="px-[10px] w-auto ml-[12px] border-dashed"
              placeholder={t("mediaLibraryScreen.tagsPlaceholder")}
              defaultSelectedValues={[]}
              data={staticTagsConfig}
              disabled={dataFetching}
              onChange={setFilterTags}
              TriggerContentComponent={ActiveFilterValueTrigger}
            /> */}
            <SortOrderSelector
              disabled={dataFetching}
              values={sortConfig}
              defaultValue={sortOrder}
              onValueChange={setSortOrder}
            />
          </div>
          <div className="flex flex-row !my-auto">
            {(!isPackage || (isPackage && selectedCategoryId && hasWriteAccess)) && (
              <MediaLibraryActionsDropdown
                isPackage={isPackage}
                disabled={!canModifyLibraryContent || dataFetching}
                apiPrefix={props.apiPrefix}
                isPrivateLibrary={isPrivateLibrary}
                categoryId={selectedCategoryId}
                parentCategoryId={selectedCategory?.id}
                onSubmitCategory={onAddUpdateMediaLibData}
                onSubmitContent={onAddUpdateMediaLibData}
              />
            )}
            {(hasWriteAccess && !selectedCategoryId && isPackage) && (
              <AddUpdatePackageDialog
                onSubmit={onAddUpdateMediaLibData}
                parentCategoryId={selectedCategory?.id}
                selectedPackage={undefined}
                apiPrefix={apiPrefix}
              />
            )}
          </div>
        </CardHeader>

        <CardContent className="p-0">
          {dataFetching && (
            <Loader2
              className={cn(
                "h-16 w-16 text-primary/60 animate-spin absolute z-10 top-1/2 left-1/2"
              )}
            />
          )}
          {(!dataFetching
            && contentItemsToDisplay.length === 0
            && (searchEnabled || categories.length === 0)
          ) && (
            <div className="text-center font-bold mt-[300px] ml-auto mr-auto">
              {searchEnabled
                ? t("mediaLibraryScreen.noSearchContent")
                : t("mediaLibraryScreen.noContent")}
            </div>
          )}

          {(!searchEnabled && categoryItemsToDisplay.length > 0) && (
            <Fragment>
              <h2 className="pt-5 pb-3">
                {t("mediaLibraryScreen.folders")}
              </h2>
              <div className="grid auto-fill-44 gap-3">
                {categoryItemsToDisplay.map((categoryItem) => {
                  return (categoryItem.media_type === "shared_category") ? (
                    <MediaContentEntity
                      key={categoryItem.id}
                      entityData={categoryItem}
                      actionsMenuEnabled={canModifyLibraryContent}
                      onClick={openContentPreview}
                      ActionsDropdown={
                        <MediaContentActionsDropdown
                          onMenuDelete={() => deleteContent(categoryItem)}
                          canEdit={
                            !["shared_resource", "shared_category"].includes(categoryItem.media_type)
                              && (isPackage || !categoryItem.is_package)
                          }
                          canDelete
                          parentCategoryId={selectedCategory?.id}
                          selectedContent={categoryItem}
                          apiPrefix={props.apiPrefix}
                          onUpdateContent={onAddUpdateMediaLibData}
                        />
                      }
                    />
                  ) : (
                    <MediaCategoryEntity
                      key={categoryItem.id}
                      entityData={categoryItem}
                      actionsMenuEnabled={
                        canModifyLibraryContent && (isPackage || !categoryItem.is_package)
                      }
                      onClick={gotoCategory}
                      ActionsDropdown={
                        <MediaCategoryActionsDropdown
                          apiPrefix={props.apiPrefix}
                          parentCategoryId={selectedCategory?.id}
                          selectedCategory={categoryItem}
                          canEdit
                          canDelete
                          onMenuDelete={() => deleteCategory(categoryItem)}
                          onUpdateCategory={onAddUpdateMediaLibData}
                        />
                      }
                    />
                  );
                })}
              </div>
            </Fragment>
          )}
          {contentItemsToDisplay.length > 0 && (
            <Fragment>
              <h2 className="pt-5 pb-3">{t("mediaLibraryScreen.files")}</h2>
              <div className="grid auto-fill-44 gap-3">
                {contentItemsToDisplay.map((contentItem) => {
                  return (
                    <MediaContentEntity
                      key={contentItem.id}
                      entityData={contentItem}
                      actionsMenuEnabled={canModifyLibraryContent}
                      onClick={openContentPreview}
                      ActionsDropdown={
                        <MediaContentActionsDropdown
                          onMenuDelete={() => deleteContent(contentItem)}
                          canEdit={
                            !["shared_resource", "shared_category"].includes(
                              contentItem.media_type
                            ) && (isPackage || !contentItem.is_package)
                          }
                          canDelete
                          parentCategoryId={selectedCategory?.id}
                          selectedContent={contentItem}
                          apiPrefix={props.apiPrefix}
                          onUpdateContent={onAddUpdateMediaLibData}
                        />
                      }
                    />
                  );
                })}
              </div>
            </Fragment>
          )}
        </CardContent>
      </ScrollArea>
    </div>
  );
}
