import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import {
  CircleUser,
  Film,
  Menu,
  Send,
  Settings,
  ShieldCheck,
  Image,
  Users,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { NavLink, useNavigate } from "react-router-dom";
import { AccountSelector } from "components/user/accountSelector";
import useUnreadMessagesCount from "app/hooks/useUnreadMessagesCount";
import { Badge } from "@/components/ui/badge";
import { AuthContext } from "app/providers/auth";
import { useSettings } from "app/hooks/settings";
import { cn } from "@/lib/utils";

export function AppLayout(props) {
  const authContext = useContext(AuthContext);
  const context = useContext(AuthContext);
  const { t } = useTranslation();
  const [settings] = useSettings();
  const unreadCount = useUnreadMessagesCount();
  const navigate = useNavigate();

  const menues = useMemo(
    () => [
      {
        label: t("patientsScreen.title"),
        icon: <Users className="h-6 w-6" />,
        link: "/patients",
        permission: "user",
      },
      {
        label: t("mediaLibraryScreen.title"),
        icon: <Film className="h-6 w-6" />,
        link: "/media-library",
        permission: "user",
      },
      ...(settings.enable_chat_feature
        ? [
            {
              label: (
                <div>
                  <span>{t("chatScreen.title")}</span>
                  {unreadCount > 0 && (
                    <Badge variant="destructive" className="ml-2">
                      {unreadCount < 100 ? unreadCount : "99+"}
                    </Badge>
                  )}
                </div>
              ),
              icon: <Send className="h-6 w-6" />,
              link: "/chat",
              permission: "user",
            },
          ]
        : []),
      {
        label: t("profileScreen.settings"),
        icon: <Settings className="h-6 w-6" />,
        link: "/account/profile",
        permission: "user",
      },
      {
        label: t("devicesScreen.title"),
        icon: <Image className="h-6 w-6" />,
        link: "/devices",
        permission: "doctor",
      },
      {
        label: t("adminScreen.dashboard.title"),
        icon: <ShieldCheck className="h-6 w-6" />,
        link: "/admin",
        permission: "master",
        className: "mt-auto"
      }
    ],
    [settings.enable_chat_feature, unreadCount, t]
  );

  return (
    <div className="flex w-screen h-screen min-h-screen max-h-screen">
      <div className="hidden border-r bg-muted/40 md:block">
        <div className="flex h-full max-h-screen flex-col w-36 gap-2 overflow-hidden">
          <div
            className="flex items-center justify-center p-1 h-20 lg:w-36 bg-auto bg-center bg-no-repeat bg-cover"
            style={{ backgroundImage: `url("/icons/logo.svg")` }}
          />
          <nav className="flex flex-col items-center text-sm font-medium flex-1 h-full">
            {menues.map((menu) => !!authContext.permission[menu.permission] && (
              <NavLink
                to={menu.link}
                className={({ isActive }) => {
                  return cn(
                    "w-full py-2 transition-all hover:bg-muted",
                    isActive ? "text-[#1949A3]" : "text-[#858992]",
                    menu.className
                  );
                }}
              >
                <div className="border-0 flex flex-1 flex-col items-center justify-center">
                  {menu.icon}
                  <span className={`text-base font-normal truncate`}>
                    {menu.label}
                  </span>
                </div>
              </NavLink>
            ))}
          </nav>
          <div className="flex items-center justify-center p-1 border-t h-14 lg:h-14 self-end w-full">
            <AccountSelector />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-0 flex-1 overflow-hidden">
        <header className="flex h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-14 lg:px-6">
          <Sheet>
            <SheetTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                className="shrink-0 md:hidden"
              >
                <Menu className="h-5 w-5" />
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="flex flex-col">
              <nav className="grid gap-2 text-lg font-medium pt-10">
                {menues.map((menu) => !!authContext.permission[menu.permission] && (
                  <NavLink
                    to={menu.link}
                    className={({ isActive }) => {
                      return (
                        "mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-muted-foreground hover:text-foreground " +
                        (isActive ? "bg-muted" : "")
                      );
                    }}
                  >
                    {menu.icon}
                    {menu.label}
                  </NavLink>
                ))}
              </nav>
            </SheetContent>
          </Sheet>
          <div className="w-full flex-1">
            {/* <form>
              <div className="relative">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search products..."
                  className="w-full appearance-none bg-background pl-8 shadow-none md:w-2/3 lg:w-1/3"
                />
              </div>
            </form> */}
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon" className="rounded-full">
                <CircleUser className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>{authContext.user.name}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  navigate("/account/profile");
                }}
              >
                {t("accountScreen.profile.settings")}
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={context.signout}>
                {t("accountScreen.profile.logout")}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </header>
        {<props.children {...props.data} />}
      </div>
    </div>
  );
}
