import React, {
  useEffect,
  useRef,
} from "react";

const withManuallySetBackgroundImg: <T extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>(
  Component: React.ComponentType<T>,
  imageUrl: string
) => React.FC<T> = (Component, imageUrl) => (props) => {
  const htmlElementRef = useRef<HTMLElement|null>(null);

  useEffect(() => {
    let objUrl: string | null = null;

    if (imageUrl.length > 0) {
      fetch(imageUrl)
        .then(response => response.blob())
        .then((blob) => {
          if (htmlElementRef.current) {
            objUrl = URL.createObjectURL(blob)
            htmlElementRef.current.style.backgroundImage=`url(${objUrl})`;
          }
        });
  }
    return () => {
      if (objUrl) {
        URL.revokeObjectURL(objUrl);
      }
    };
  }, []);


  return (
      <Component ref={htmlElementRef} {...props}/>
  );
};

export default withManuallySetBackgroundImg;